<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-form">
    <el-form-item prop="mp">
      <el-input
        v-model="ruleForm.mp"
        :maxlength="11"
        placeholder="请输入手机号"
      ></el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-input
        v-model="ruleForm.captcha"
        placeholder="请输入图形验证码"
        class="reg-create-captcha-ipt"
      >
        <div
          slot="append"
          v-bind:style="{ background: captchaBg }"
          class="captcha-img"
          @click="refreshCaptcha"
        ></div>
      </el-input>
    </el-form-item>
    <el-form-item style="margin-bottom: 15px" prop="sms">
      <el-input placeholder="请输入短信验证码" v-model="ruleForm.sms">
        <template slot="append">
          <div
            v-if="invsec <= 0"
            @click="getMobileCode"
            style="cursor: pointer"
          >
            获取验证码
          </div>
          <div v-if="invsec > 0">{{ invsec }}秒后重试</div>
        </template>
      </el-input>
    </el-form-item>
    <div style="margin-bottom: 20px" class="flex">
      <div class="flex-1">
        <!-- <el-checkbox style="padding:5px 0;font-size:12px;color:#808080;line-height:19px;" v-model="ruleForm.remember">记住密码登录</el-checkbox> -->
      </div>
      <a @click="forget" style="line-height: 30px">忘记密码？</a>
    </div>
    <el-form-item style="margin-bottom: 20px">
      <el-button
        @click="submitForm('ruleForm')"
        @keyup.enter="submitForm('ruleForm')"
        style="width: 100%; background: #162449; border-color: #162449"
        type="primary"
        round
        >登录</el-button
      >
    </el-form-item>
    <el-form-item style="margin-bottom: 0;text-align: center;">
      <a @click="regist"
        >立即注册</a
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { getCaptCha, sendLoginSmsCode } from "@/service/common";
import { phoneReg } from "@/utils";
import { loginByMobile, createMobileAccountAgo } from "@/service/account";
export default {
  props: ["phone"],
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = phoneReg;
        if (reg.test(value)) {
          createMobileAccountAgo({
            mobile: this.ruleForm.mobile,
          })
            .then((rst) => {
              if (rst.reg == 0) {
                return callback(new Error("该手机号尚未注册"));
              } else {
                callback();
              }
            })
            .catch((err) => {
              console.log(err);
              callback();
            });
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      ruleForm: {
        mp: "",
        captcha: "",
        sms: "",
        // 记住我
        remember: false,
      },
      rules: {
        mp: [{ validator: checkPhone, trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        sms: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
      },
      captchaBg: null,
      captchaToken: null,
      invsec: 0,
      timer: null,
    };
  },
  created() {
    if (this.phone) {
      this.ruleForm.mp = this.phone;
    }
  },
  mounted() {
    // enter快捷键登录
    let _self = this;
    this.$parent.$refs.frame.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 13 || key == 100) {
        _self.submitForm("ruleForm");
      }
    };
    this.refreshCaptcha();
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    refreshCaptcha() {
      getCaptCha()
        .then((rsp) => {
          this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(
            rsp.svg
          )}') no-repeat center`;
          this.captchaToken = rsp.token;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    getMobileCode() {
      sendLoginSmsCode(this.ruleForm.mp)
        .then(() => {
          this.invsec = 60;
          this.timer = setInterval(() => {
            if (this.invsec == 0) {
              clearInterval(this.timer);
              return;
            }
            this.invsec--;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let login_obj = {
            mobile: this.ruleForm.mp,
            code: this.ruleForm.sms,
            captcha: this.ruleForm.captcha,
            captcha_token: this.captchaToken,
          };
          if (this.ruleForm.remember) {
            login_obj.remember = 1;
          }
          console.log(login_obj);
          loginByMobile(login_obj, this)
            .then(() => {
              this.$emit("success");
            })
            .catch((err) => {
              this.refreshCaptcha(); // 刷新二维码
              this.$message.error(err.message);
              return false;
            });
        } else {
          this.refreshCaptcha(); // 刷新二维码
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    regist() {
      // this.$router.push('/register');
      this.$emit("register");
    },
    forget() {
      this.$emit("onForget");
    },
  },
};
</script>
<style>
.reg-create-captcha-ipt .el-input-group__append {
  padding: 0 !important;
  overflow: hidden;
}
</style>
<style scoped>
a {
  color: #152348;
}
.captcha-img {
  height: 38px;
  background-size: 100% 100% !important;
  width: 130px;
  cursor: pointer;
}
</style>