<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-form">
      <el-form-item prop="mobile">
        <el-input
          placeholder="请输入手机号"
          v-model="ruleForm.mobile"
          :maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item prop="passwd">
        <el-input
          placeholder="请输入密码"
          v-model="ruleForm.passwd"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 15px" prop="captcha">
        <el-input
          v-model="ruleForm.captcha"
          placeholder="请输入图形验证码"
          class="reg-create-captcha-ipt"
        >
          <div
            slot="append"
            v-bind:style="{ background: captchaBg }"
            class="captcha-img"
            @click="refreshCaptcha"
          ></div>
        </el-input>
      </el-form-item>
      <div style="margin-bottom: 20px" class="flex">
        <div class="flex-1">
          <el-checkbox
            style="
              padding: 5px 0;
              font-size: 12px;
              color: #808080;
              line-height: 19px;
            "
            v-model="ruleForm.remember"
            >记住密码登录</el-checkbox
          >
        </div>
        <a @click="forget" style="line-height: 30px">忘记密码？</a>
      </div>
    </el-form>
    <div style="margin-bottom: 20px">
      <el-button
        @click="submitForm('ruleForm')"
        @keyup.enter="submitForm('ruleForm')"
        style="width: 100%; background: #162449; border-color: #162449"
        type="primary"
        round
        >登录</el-button
      >
    </div>
    <div style="text-align: center">
      <a @click="regist">立即注册</a>
    </div>
  </div>
</template>
<style>
.reg-create-captcha-ipt .el-input-group__append {
  padding: 0 !important;
  overflow: hidden;
}
</style>
<script>
import { getCaptCha } from "@/service/common";
import { getUser } from "@/service/blc";
import { phoneReg } from "@/utils";
import { Base64 } from "js-base64";
import { loginByMobileAndPwd, createMobileAccountAgo } from "@/service/account";
export default {
  props: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = phoneReg;
        if (reg.test(value)) {
          createMobileAccountAgo({
            mobile: this.ruleForm.mobile,
          })
            .then((rst) => {
              if (rst.reg == 0) {
                return callback(new Error("该手机号尚未注册"));
              } else {
                callback();
              }
            })
            .catch((err) => {
              console.log(err);
              callback();
            });
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      ruleForm: {
        mobile: "",
        passwd: "",
        captcha: "",
        captchaToken: "",
        // 记住我
        remember: false,
      },
      rules: {
        mobile: [{ validator: checkPhone, trigger: "blur" }],
        passwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
      },
      captchaBg: null,
    };
  },
  created() {},
  mounted() {
    // enter快捷键登录
    let _self = this;
    this.$parent.$refs.frame.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 13 || key == 100) {
        _self.submitForm("ruleForm");
      }
    };
    this.refreshCaptcha();
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    refreshCaptcha() {
      getCaptCha()
        .then((rsp) => {
          this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(
            rsp.svg
          )}') no-repeat center`;
          this.ruleForm.captchaToken = rsp.token;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.remember) {
            this.ruleForm.remember = 1;
          }
          loginByMobileAndPwd(this.ruleForm)
            .then((res) => {
              getUser({
                phoneOrOpenid: this.ruleForm.mobile,
                password: Base64.encode(this.ruleForm.passwd),
                orgId: res.comp.id,
                tenantId: 0,
              });
              this.$emit("success");
            })
            .catch((err) => {
              this.ruleForm.remember = true;
              this.refreshCaptcha(); // 刷新二维码
              this.$message.error(err.message);
              return false;
            });
        } else {
          this.refreshCaptcha(); // 刷新二维码
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    regist() {
      // this.$router.push('/register');
      this.$emit("register");
    },
    forget() {
      this.$emit("onForget");
    },
  },
};
</script>
<style scoped>
a {
  color: #152348;
}
.captcha-img {
  height: 38px;
  background-size: 100% 100% !important;
  width: 130px;
  cursor: pointer;
}
</style>